import axiosInstance from "@/axios";

class endossoController {

  async getReasonEndorsementRequest(payload) {
    return await axiosInstance
      .post(`/api/Endorsement/ValidateEndorsement`, payload)
      .then(response => response.data.Response)
      .catch(data => {
        return Promise.reject(data);
      });
  }

  async restoreInsurancePremium(payload) {
    return await axiosInstance
      .post(`api/RestoreInsurancePremium/ValidateRestore`, data)
      .then(response => response.data.Response);
  }

  async getEndorsementType() {
    return await axiosInstance
      .get(`/api/Endorsement/GetEndorsementType`)
      .then(response => response.data.Response);
  }

  async getListEndorsementPolicy(policyUniqueId) {
    return await axiosInstance
      .get(
        `/api/Endorsement/ListEndorsementsByPolicy?policyUniqueId=${policyUniqueId}`
      )
      .then(response => response.data.Response);
  }

  async getEndorsementAttachmentsList(endorsementId) {
    return await axiosInstance
      .get(
        `api/Endorsement/ListEndorsementAttachments?endorsementId=${endorsementId}`
      )
      .then(response => response.data.Response);
  }

  async downloadEndorsementDocument(payload) {
    return await axiosInstance
      .get(
        `api/Endorsement/DownloadEndorsementDocument?proposalUniqueId=${payload}`
      )
      .then(response => response.data.Response);
  }

  async getEndorsementRequestDocumentArchives(requestDocumentUniqueId) {
    return await axiosInstance
      .get(
        `api/Endorsement/GetEndorsementRequestDocumentArchives?requestDocumentUniqueId=${requestDocumentUniqueId}`
      )
      .then(response => response.data.Response);
  }

  async downloadEndorsementArchive() {
    return await axiosInstance
      .get(
        `api/Endorsement/DownloadEndorsementArchive?archiveUniqueId=${archiveUniqueId}`
      )
      .then(response => response.data.Response);
  }

  async resetEndosso(uniqueId) {
    return await axiosInstance
      .post(
        `api/Endorsement/CancelRequestEndorsement?PolicyUniqueId=${uniqueId}`
      )
      .then(response => response.data.Response);
  }

  async loadEndorsementDocuments(params) {
    return await axiosInstance
      .get(
        `/api/Endorsement/GetPolicyEndorsementRequestDocument?endorsementTypeId=${params.endorsementId}&endorsementReasonId=${params.EndorsementReasonId}&modalityId=${params.modalityId}&endorsementChangeTypeIds=${params.endorsementChangeTypeIds}`
      )
      .then(response => response.data.Response);
  }

  async validateCanRequestEndorsementType(params) {
    return await axiosInstance
      .post(
        `api/Endorsement/ValidateCanRequestEndorsementType?policyUniqueId=${params.policyUniqueId}&type=${params.type}`
      )
      .then(response => response.data.Response);
  }

  async createEndorsementPolicy(payload) {
    return await axiosInstance
      .post(`api/Endorsement/CreateEndorsement`, payload)
      .then(response => response.data.Response);
  }
  async endossoCadastro(proposalUniqueId) {
    return await axiosInstance
      .post(
        `api/Endorsement/ExecuteAutomaticRegistrationEndorsementPolicy?proposalUniqueId=${proposalUniqueId}`
      )
      .then(response => response.data.Response);
  }

  async endossoAprovacaoCredito(proposalUniqueId) {
    return await axiosInstance
      .post(
        `api/Endorsement/ExecuteAutomaticCreditApprovalEndorsementPolicy?proposalUniqueId=${proposalUniqueId}`
      )
      .then(response => response.data.Response);
  }

  async endossoResseguro(proposalUniqueId) {
    return await axiosInstance
      .post(
        `api/Endorsement/ExecuteAutomaticReinsuranceEndorsementPolicy?proposalUniqueId=${proposalUniqueId}`
      )
      .then(response => response.data.Response);
  }

  async endossoSubscricao(payload) {
    return await axiosInstance
      .post(
        `api/Endorsement/ExecuteAutomaticSubscriptionEndorsementPolicy`,
        payload
      )
      .then(response => response.data.Response);
  }

  async approveEndorsement(payload) {
    return await axiosInstance
      .post(`api/Endorsement/ApproveEndorsement`, payload)
      .then(response => response.data.Response);
  }

  async getPercentageLimitPremiumRelationInsuredAmount() {
    return await axiosInstance
      .get(`/api/Core/GetPercentageLimitPremiumRelationIssuredAmount`)
      .then(response => response.data.Response);
  }

  async getNumberOfDaysToCancelPolicy() {
    return await axiosInstance
      .get(`/api/policy/GetNumberOfDaysToCancelPolicy`)
      .then(response => response.data.Response);
  }
  async getCompareChanges(payload) {
    return await axiosInstance
      .get(
        `/api/endorsement/GetCompareChanges?proposalUniqueId=${payload.uniqueId}`
      )
      .then(response => response.data.Response);
  }

  async adicionarParte(data) {
    return await axiosInstance
      .post(`api/ContraGarantia/AdicionarParte`, data)
      .then(response => response.data.Response);
  }
}

export default new endossoController();
