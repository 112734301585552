<template>
  <vs-row class="endosso-section">
    <slot> </slot>
    <div
      class="disableddates cursor-pointer "
      @click="setDateChange()"
      v-if="col != 4"
    >
      <div class="content cursor-pointer">
        Alterar Datas
        <i
          style="margin-left: 5px;"
          class="onpoint-pencil-simple-line icon-font  float-right text-lg "
        ></i>
      </div>
    </div>
    <vs-col
      :vs-lg="col"
      vs-sm="12"
      :class="`container-element`"
      v-if="showItens.dataInicial"
    >
      <p class="label-content label">{{ label.dataInicial }}</p>
      <ValidationProvider
        :name="label.dataInicial"
        rules="required"
        v-slot="{ errors }"
      >
        <vs-input
          v-if="label.dataInicial == `Início vigência da apólice`"
          class="w-full"
          size="large"
          :value="$utils.dateToDDMMYYYY(dataInicial)"
          :disabled="true"
        />

        <datepicker
          v-else
          :language="ptBR"
          class="w-full"
          format="dd/MM/yyyy"
          v-model="dataInicial"
          :disabled="disabledItens.dataInicial || hasFixedDateStart"
          :disabled-dates="disabledDates"
        />
        <span class="text-danger text-sm" v-show="errors[0]">
          {{ errors[0] }}
        </span>
      </ValidationProvider>
    </vs-col>
    <vs-col
      :vs-lg="col"
      vs-sm="12"
      :class="`container-element`"
      v-if="showItens.dias"
    >
      <p class="label-content label">{{ label.dias }}</p>
      <ValidationProvider
        :name="label.dias"
        :rules="`required|mindays:${minDias || 1}|maxdays:${maxDias || false}`"
        v-slot="{ errors }"
      >
        <vs-input
          class="w-full"
          size="large"
          v-model.number="prazo"
          :disabled="disabledItens.dias"
          @blur="setPrazoDias()"
        />
        <span class="text-danger text-sm" v-show="errors[0]">
          {{ errors[0] }}
        </span>
      </ValidationProvider>
    </vs-col>

    <vs-col
      :vs-lg="col"
      vs-sm="12"
      :class="`container-element`"
      v-if="showItens.dataFinal"
    >
      <p class="label-content label">{{ label.dataFinal }}</p>

      <ValidationProvider
        :name="label.dataFinal"
        :rules="`required`"
        v-slot="{ errors }"
      >
        <datepicker
          :language="ptBR"
          class="w-full"
          format="dd/MM/yyyy"
          v-model="dataFinal"
          :disabled="disabledItens.dataFinal"
          :disabled-dates="disabledDatesEnd"
        />
        <span class="text-danger text-sm" v-show="errors[0] || showErroData">
          {{ errors[0] || erroData }}
        </span>
      </ValidationProvider>
    </vs-col>
  </vs-row>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: { Datepicker },
  props: {
    col: { type: Number, default: 4 },
    dataInicio: { require: true },
    dataFim: { require: true },
    dias: { type: Number },
    minDias: { type: Number, default: 0 },
    maxDias: { type: Number, default: 0 },
    label: {
      Type: Object,
      default() {
        return {
          dataInicial: "Início vigência",
          dataFinal: "Fim vigência",
          dias: "Prazo dias"
        };
      }
    },
    disabledItens: {
      Type: Object,
      default() {
        return { dataInicial: false, dataFinal: false, dias: true };
      }
    },
    showItens: {
      Type: Object,
      default() {
        return { dataInicial: true, dataFinal: true, dias: true };
      }
    },
    modalidade: {
      type: String
    }
  },
  data() {
    return {
      hasFixedDateStart: false,
      PrazoDias: "",
      showErroData: false,
      erroData: "",
      ptBR,
      disabledDates: {
        to: new Date(2020, 0, 1),
        from: new Date(2100, 0, 26)
      },
      disabledDatesEnd: {
        to: new Date(2020, 0, 1),
        from: new Date(2100, 0, 26)
      }
    };
  },
  computed: {
    ...mapGetters("endosso-module", [
      "endosso",
      "endossoPayload",
      "endossoCreatePayload"
    ]),
    ...mapGetters("apolice-module", ["apolice"]),

    dataInicial: {
      get(value) {
        return this.dataInicio;
      },
      set(value) {
        this.$emit("updateDataInicial", value);
      }
    },

    dataFinal: {
      get() {
        return this.dataFim;
      },
      set(value) {
        this.$emit("updateDataFinal", value);
      }
    },

    prazo: {
      get() {
        return this.dias;
      },

      set(value) {
        // this.$emit("updatePrazo", value);
      }
    }
  },
  methods: {
    ...mapMutations("endosso-module", [
      "updatehasFixedStartDate",
      "updateDeadlineDays"
    ]),

    setDateChange() {
      this.$emit("updateDateChange", {
        alterado: true,
        modalidade: this.modalidade
      });
    },

    setPrazoDias() {
      this.prazo = this.PrazoDias;
    },

    ValidFixedDate() {
      console.log(
        "ValidFixedDate",
        this.$store.state["apolice-module"].Rules[0].StartDate
      );
      if (this.$store.state["apolice-module"].Rules[0].StartDate) {
        setTimeout(() => {
          this.updatehasFixedStartDate(true);
          this.hasFixedDateStart = true;
        }, 0);
        this.updateDeadlineDays(this.apolice.DeadlineDays);
        setTimeout(() => {
          this.$emit("updatePrazo", true);
        }, 2000);
        let Rules = this.$store.state["apolice-module"].Rules[0];
        const YYYY = this.$moment(Rules.StartDate).format("YYYY");
        const MM = this.$moment(Rules.StartDate).format("MM");
        const DD = this.$moment(Rules.StartDate).format("DD");
        this.disabledDates.to = new Date(YYYY, MM - 1, DD);
        this.dataInicial = new Date(YYYY, MM - 1, DD);
      }
    },

    ValidMinDate() {
      if (this.$store.state["apolice-module"].Rules[1].EndDate) {
        let RulesEnd = this.$store.state["apolice-module"].Rules[1].EndDate;
        const EYYYY = this.$moment(RulesEnd).format("YYYY");
        const EMM = this.$moment(RulesEnd).format("MM");
        const EDD = this.$moment(RulesEnd).format("DD");
        this.disabledDatesEnd.to = new Date(EYYYY, EMM - 1, EDD);
      }
      if (this.$store.state["apolice-module"].Rules[1].StartDate) {
        let RulesStart = this.$store.state["apolice-module"].Rules[1].StartDate;
        const EYYYY2 = this.$moment(RulesStart).format("YYYY");
        const EMM2 = this.$moment(RulesStart).format("MM");
        const EDD2 = this.$moment(RulesStart).format("DD");
        this.disabledDates.to = new Date(EYYYY2, EMM2 - 1, EDD2);
      }
    },

    ValidMaxDate() {
      if (this.$store.state["apolice-module"].Rules[2].EndDate) {
        let RulesEnd = this.$store.state["apolice-module"].Rules[2].EndDate;
        const EYYYY = this.$moment(RulesEnd).format("YYYY");
        const EMM = this.$moment(RulesEnd).format("MM");
        const EDD = this.$moment(RulesEnd).format("DD");
        this.disabledDatesEnd.from = new Date(EYYYY, EMM - 1, EDD);
      }
      if (this.$store.state["apolice-module"].Rules[2].StartDate) {
        let RulesStart = this.$store.state["apolice-module"].Rules[2].StartDate;
        const EYYYY2 = this.$moment(RulesStart).format("YYYY");
        const EMM2 = this.$moment(RulesStart).format("MM");
        const EDD2 = this.$moment(RulesStart).format("DD");
        let finalDay = parseInt(EDD2);
        this.disabledDates.from = new Date(EYYYY2, EMM2 - 1, finalDay + 1);
      }
    }
  },
  created() {
    this.ValidMinDate();
    this.ValidMaxDate();
  },
  mounted() {
    this.PrazoDias = this.dias;
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vdp-datepicker input {
  height: 47px;
}
.endosso-section {
  position: relative;
}
.disableddates {
  opacity: 0;
  position: absolute;
  background: rgba(var(--vs-primary), 0.85);
  width: 74%;
  right: 7px;
  z-index: 9;
  height: 48px;
  border-radius: 35px;
  padding: 10px 15px;
  box-shadow: 0px 0px 37px 5px rgba(var(--vs-primary), 0.15);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disableddates:hover {
  opacity: 1;
}

.icon-font::before{
  font-size: 16px;
  position: relative;
  top: 2px;
}
</style>
