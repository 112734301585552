var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "container", attrs: { id: "endosso-alteracao" } },
    [
      _c(
        "vs-col",
        { attrs: { "vs-w": "12" } },
        [
          _vm.changed
            ? _c(
                "vs-alert",
                {
                  staticClass: "alert-update",
                  attrs: {
                    active: _vm.changed,
                    color: "warning",
                    icon: "alert",
                  },
                },
                [
                  _c("b", [
                    _vm._v(" Edição iniciada em: " + _vm._s(_vm.startUpdate)),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        {
          staticClass: "vx-col md:w-8/12 w-full md:pr-10 update-date",
          staticStyle: { position: "relative" },
        },
        [
          _vm.apolice.PolicyUniqueId
            ? _c(
                "dados-proposal-parts",
                {
                  attrs: { enableChangeNomeSocial: _vm.enableChangeNomeSocial },
                },
                [
                  _c(
                    "vs-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.changed,
                          expression: "changed",
                        },
                      ],
                      staticClass: "container-btn-changes",
                      attrs: {
                        "vs-lg": "6",
                        "vs-sm": "12",
                        "vs-type": "flex",
                        "vs-justify": "end",
                        "vs-align": "end",
                      },
                    },
                    [
                      _c("visualizar-alteracoes", {
                        attrs: {
                          objectDiff: _vm.objectDiff,
                          changed: _vm.changed,
                          showDesfazer: true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            { staticClass: "margin-bottom" },
            [
              _c(
                "vs-col",
                { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      size: "large",
                      label: "Grupo modalidades",
                      readonly: true,
                      disabled: "",
                    },
                    model: {
                      value: (_vm.apolice.Modality || {}).GroupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.apolice.Modality || {}, "GroupName", $$v)
                      },
                      expression: "(apolice.Modality || {}).GroupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      size: "large",
                      label: "Modalidade principal",
                      readonly: true,
                      disabled: "",
                    },
                    model: {
                      value: (_vm.apolice.Modality || {}).Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.apolice.Modality || {}, "Name", $$v)
                      },
                      expression: "(apolice.Modality || {}).Name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      size: "large",
                      label: "Taxa aplicada (%)",
                      readonly: true,
                      disabled: "",
                    },
                    model: {
                      value: (_vm.apolice || {}).RiskRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.apolice || {}, "RiskRate", $$v)
                      },
                      expression: "(apolice || {}).RiskRate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.apolice && _vm.apolice.PolicyUniqueId
            ? _c(
                "update-date",
                {
                  attrs: {
                    dataInicio: _vm.vigenciaInicioPrincipal,
                    dataFim: _vm.vigenciaFimPrincipal,
                    dias: _vm.vigenciaDiasPrincipal,
                    maxDias: 999999,
                    minDias: 0,
                    col: !_vm.importanciaSeguradaChanged ? 3 : 4,
                    disabledItens: _vm.disabledItensPrincipal,
                    label: _vm.labelItens,
                    disabledDates: _vm.disabledDatesInicio(),
                    modalidade: "principal",
                  },
                  on: {
                    updateDataInicial: _vm.updateDataInicial,
                    updateDataFinal: _vm.updateDataFinal,
                    updatePrazo: _vm.updatePrazo,
                    updateDateChange: _vm.updateDateChange,
                  },
                },
                [
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element mb-5",
                        attrs: {
                          "vs-lg": !_vm.importanciaSeguradaChanged ? 3 : 4,
                          "vs-sm": "12",
                        },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Importância segurada"),
                        ]),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "Importância segurada",
                            rules: `required:${true}|minInsurancePremium:${
                              (_vm.apolice.Modality || {}).MinInsurancePremium
                            },${(_vm.apolice.Modality || {}).Name},${
                              _vm.percLimit
                            }`,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("currency-input", {
                                      staticClass:
                                        "w-full vs-inputx vs-input--input large hasValue",
                                      on: {
                                        change: _vm.changeImportanciaSegurada,
                                      },
                                      model: {
                                        value: _vm.importanciaSegurada,
                                        callback: function ($$v) {
                                          _vm.importanciaSegurada = $$v
                                        },
                                        expression: "importanciaSegurada",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(errors[0]) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1838069793
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm.importanciaSeguradaChanged
                      ? _c(
                          "vs-col",
                          {
                            staticClass: "mb-5",
                            attrs: { "vs-lg": "4", "vs-sm": "12" },
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                size: "large",
                                label: "Vigência inicial Apólice",
                                disabled: true,
                                value: _vm.$utils.dateToDDMMYYYY(
                                  _vm.apolice.StartDate
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.importanciaSeguradaChanged
                      ? _c(
                          "vs-col",
                          {
                            staticClass: "mb-5",
                            attrs: { "vs-lg": "4", "vs-sm": "12" },
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                size: "large",
                                label: "Vigência final Apólice",
                                disabled: true,
                                value: _vm.$utils.dateToDDMMYYYY(
                                  _vm.apolice.EndDate
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                ],
                2
              )
            : _vm._e(),
          ((_vm.apolice || {}).ComplementaryModality || {}).Id
            ? [
                _c(
                  "vs-row",
                  { staticClass: "mt-8 margin-bottom" },
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            size: "large",
                            label: "Modalidade complementar",
                            readonly: true,
                            disabled: "",
                          },
                          model: {
                            value: _vm.apolice.ComplementaryModality.Name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.apolice.ComplementaryModality,
                                "Name",
                                $$v
                              )
                            },
                            expression: "apolice.ComplementaryModality.Name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            size: "large",
                            label: "Taxa aplicada (%)",
                            readonly: true,
                            disabled: "",
                          },
                          model: {
                            value: (_vm.apolice || {}).RiskRate,
                            callback: function ($$v) {
                              _vm.$set(_vm.apolice || {}, "RiskRate", $$v)
                            },
                            expression: "(apolice || {}).RiskRate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.apolice && _vm.apolice.PolicyUniqueId
                  ? _c(
                      "update-date",
                      {
                        attrs: {
                          dataInicio: _vm.vigenciaInicioComplementar,
                          dataFim: _vm.vigenciaFimComplementar,
                          dias: _vm.vigenciaDiasComplementar,
                          maxDias: 999999,
                          minDias: 0,
                          col: !_vm.importanciaSeguradaComplementaryChanged
                            ? 3
                            : 4,
                          disabledItens: _vm.disabledItensComplementar,
                          label: _vm.labelItens,
                          modalidade: "complementar",
                        },
                        on: {
                          updateDataInicial: _vm.updateDataInicialComplementar,
                          updateDataFinal: _vm.updateDataFinalComplementar,
                          updatePrazo: _vm.updatePrazoComplementar,
                          updateDateChange: _vm.updateDateChange,
                        },
                      },
                      [
                        [
                          _c(
                            "vs-col",
                            {
                              staticClass: "container-element mb-5",
                              attrs: {
                                "vs-lg":
                                  !_vm.importanciaSeguradaComplementaryChanged
                                    ? 3
                                    : 4,
                                "vs-sm": "12",
                              },
                            },
                            [
                              _c("p", { staticClass: "label-content label" }, [
                                _vm._v("Importância segurada"),
                              ]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Importância segurada",
                                  rules: `required|minInsurancePremium:${
                                    (_vm.apolice.ComplementaryModality || {})
                                      .MinInsurancePremium
                                  },${
                                    (_vm.apolice.ComplementaryModality || {})
                                      .Name
                                  },${_vm.percLimit}`,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("currency-input", {
                                            staticClass:
                                              "w-full vs-inputx vs-input--input large hasValue",
                                            on: {
                                              change:
                                                _vm.changeImportanciaSeguradaComplementar,
                                            },
                                            model: {
                                              value:
                                                _vm.importanciaSeguradaComplementar,
                                              callback: function ($$v) {
                                                _vm.importanciaSeguradaComplementar =
                                                  $$v
                                              },
                                              expression:
                                                "importanciaSeguradaComplementar",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]",
                                                },
                                              ],
                                              staticClass:
                                                "text-danger text-sm",
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(errors[0]) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1362400801
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm.importanciaSeguradaComplementaryChanged
                            ? _c(
                                "vs-col",
                                {
                                  staticClass: "mb-5",
                                  attrs: { "vs-lg": "4", "vs-sm": "12" },
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      size: "large",
                                      label: "Vigência inicial Apólice",
                                      readonly: true,
                                      disabled: "",
                                      value: _vm.$utils.dateToDDMMYYYY(
                                        _vm.apolice.ComplementaryStartDate
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.importanciaSeguradaComplementaryChanged
                            ? _c(
                                "vs-col",
                                {
                                  staticClass: "mb-5",
                                  attrs: { "vs-lg": "4", "vs-sm": "12" },
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      size: "large",
                                      label: "Vigência final Apólice",
                                      readonly: true,
                                      disabled: "",
                                      value: _vm.$utils.dateToDDMMYYYY(
                                        _vm.apolice.ComplementaryEndDate
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.endossoObjeto && _vm.endossoObjeto.length
            ? _c("contractual-terms", {
                attrs: {
                  defaultTags: _vm.defaultTags,
                  tagsSeguradora: _vm.tagsSeguradora,
                  isEndosso: true,
                  endossoObjeto: _vm.endossoObjeto,
                  disabledTagFieldsEndosso: _vm.disabledTagFieldsEndosso,
                  currentTab: "1",
                },
                on: {
                  updatePropostaTermos: _vm.updatePropostaTermos,
                  textoObjetoCompleto: _vm.clonarTextoObjetoCompleto,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "custom-object text-right" },
            [
              _vm.enableChangeObject
                ? _c(
                    "vs-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        disabled: _vm.desableChangeObjectBtn,
                      },
                      on: { click: _vm.openChangeObjetctModal },
                    },
                    [
                      _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                        _vm._v(" Alterar objeto completo "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "vs-popup",
                {
                  staticClass: "popup-aditional-coverage",
                  attrs: {
                    "button-close-hidden": true,
                    title: " ",
                    active: _vm.modalCustomObject,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.modalCustomObject = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-6" },
                    [
                      _c("h2", { staticClass: "font-bold text-primary" }, [
                        _vm._v("Alterar objeto completo"),
                      ]),
                      _c("br"),
                      _c("jodit-editor", {
                        staticStyle: { "min-height": "500px" },
                        attrs: { config: _vm.joditConfig },
                        model: {
                          value: _vm.cloneTextoObjetoCompleto,
                          callback: function ($$v) {
                            _vm.cloneTextoObjetoCompleto = $$v
                          },
                          expression: "cloneTextoObjetoCompleto",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8 mr-3",
                              attrs: { color: "grey" },
                              on: {
                                click: function ($event) {
                                  _vm.modalCustomObject = false
                                },
                              },
                            },
                            [_vm._v("Cancelar")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addCustomObject()
                                },
                              },
                            },
                            [_vm._v("Prosseguir")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          ((_vm.apolice || {}).Modality || {}).Id
            ? _c("particular-clauses-select", {
                attrs: {
                  proposal: { ..._vm.apolice, ..._vm.endosso },
                  modalityId: _vm.apolice.Modality.Id,
                  proposalClausesRequired:
                    _vm.proposalClausesProposalRequiredValues || [],
                  isEndossoAlteracao: true,
                },
                on: {
                  "proposal-terms-values-change":
                    _vm.particularClausesProposalTermsValuesChanged,
                },
                model: {
                  value: _vm.selectedParticularClauses,
                  callback: function ($$v) {
                    _vm.selectedParticularClauses = $$v
                  },
                  expression: "selectedParticularClauses",
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "aside",
        {
          staticClass:
            "vx-col md:w-4/12 w-full md:mt-0 mt-10 md:pt-0 pt-10 md:pl-10",
        },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "12" } },
                [
                  _c("p", { staticClass: "font-semibold mb-3" }, [
                    _vm._v("Coberturas adicionais"),
                  ]),
                  (_vm.apolice.Coverages || []).length > 0
                    ? _vm._l(_vm.apolice.Coverages, function (cobertura) {
                        return _c("p", { key: cobertura.Id }, [
                          _c("i", {
                            staticClass:
                              "text-lg onpoint-check-circle text-primary pr-3",
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(cobertura.Name) +
                              "\n          "
                          ),
                        ])
                      })
                    : _c("p", [
                        _vm._v(
                          "APÓLICE NÃO POSSUI CONTRATAÇÃO DE COBERTURAS ADICONAIS"
                        ),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-3" },
            [
              _c(
                "vs-col",
                { staticClass: "container-element", attrs: { "vs-w": "12" } },
                [
                  _c("p", { staticClass: "label-content label" }, [
                    _vm._v("Prêmio da apólice"),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input large hasValue",
                    attrs: {
                      value: (_vm.apolice || {}).InsurancePremium,
                      disabled: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("revisao-premio", {
            attrs: {
              apolice: _vm.apolice,
              loadingPremium: _vm.loadingPremium,
              restituir: _vm.restituir,
              IsBankInformationNeeded: _vm.IsBankInformationNeeded,
            },
            on: { updatePolicyHolderBankInfo: _vm.updateEndosso },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }